.watching-creation-show {
    background-color: gray;
}

.watching-creation-season {
    background-color: darkgray;
    margin-left: 25px;
}

.watching-creation-disc {
    background-color: lightgray;
    margin-left: 25px;
}
