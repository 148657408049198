.background-color--lightpink {
    background-color: lightpink !important;
}

.background-color--lightyellow {
    background-color: #ffff99 !important;
}

.background-color--lightgreen {
    background-color: lightgreen !important;
}
