.multi-consumes-table {
    width: 1600px;
}

.multi-consumes-column {

}

.multi-consumes-cell {
    float: left;
    border: solid thin;
    width: 222px;
    height: 40px;
    padding: 5px;
    text-align: center;
}
