#flatAdvertisementSearchTable_wrapper {
    margin: 15px 20px;
}

.create-flat-advertisement-search-button {
    margin-left: 15px;
}

#flatAdvertisementSearchTable_wrapper input {
    border-radius: 0.75em;
    padding: 0 10px;
}

.remove-flat-advertisement-search-icon {
    color: red;
}
