#create-form {
    width: 300px;
    margin: 0 auto;
}

#create-form input {
    width: 100%;
    border-radius: 0.75em;
    padding: 0 10px;
}

#create-form textarea {
    width: 100%;
    height: 100px;
    border-radius: 0.75em;
    padding: 0 10px;
}

#create-form button {
    float: right;
    margin-top: 15px;
}

#create-form select {
    width: 100%;
    border-radius: 0.75em;
    padding: 0 10px;
}
