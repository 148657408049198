#header {
    margin: 5px;
}

h1 {
    text-align: center;
}

h1 a {
    color: black;
}

h2 {
    text-align: center;
    margin: 10px 0 !important;
}

.nav {
    margin: 0 15px;
}

.nav-tabs {
    border-top: 1px solid #dee2e6;
}

#loading {
    position: absolute;
    background-color: lightgray;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: auto 0;
    display: none;
}

.border-top {
    border-top: solid;
}

.end-of-page-space-to-scroll {
    height: 1200px;
}

.color-black {
    color: black;
}

.background-color--lightgreen {
    background-color: lightgreen;
}

@keyframes blinking {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.blinking {
    animation: blinking 3s linear infinite;
}
