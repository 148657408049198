#search-results {
    margin-top: 120px;
}

.search-result {
    border: solid thin;

    margin-bottom: 25px;

    padding: 10px;
}
