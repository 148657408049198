body {
    background-color: lightgray;
    margin: 0;
}

.disabled-link {
    color: #565656;
}

.create-button {
    margin-left: 15px;
}

.delete-button {
    float: right;
}

h3 {
    text-align: center;
}

.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

table.dataTable tbody tr:hover {
	background-color: lightblue !important;
}

.float-right {
	float: right
}

.content-right {
	text-align: right !important;
}

.fa-warning {
    color: #8B8000;
}
