.alert-warning {
    padding: 5px 10px;
}
.alert-success {
    padding: 5px 10px;
}
.alert-danger {
    padding: 5px 10px;
}
.alert-info {
    padding: 5px 10px;
}
