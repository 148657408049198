#main-page-content {
    margin: 0 20%;
}

.main-page-element {
    position: relative;
    float: left;
    border: solid 5px;
    border-color: black;
    border-radius: 0.5em;
    width: 200px;
    height: 200px;
    margin: 30px 0 0 30px;
}

.main-page-element-number {
    position: absolute;
    border: solid 5px;
    border-color: black;
    border-radius: 20px;
    width: 41px;
    height: 41px;
    background-color: white;
    top: -23px;
    right: -23px;
}

.main-page-element-number span {
    position: absolute;
    color: black;
    font-weight: bold;
    width: 40px;
    height: 40px;
    top: 1px;
    left: -5px;
    text-align: center;
    vertical-align: center;
    font-size: large;
}

.main-page-element-symbol {
    width: 190px;
    height: 180px;
    text-align: center;
}

.main-page-element-symbol i {
    margin-top: 33px;
    font-size: 100px;
}

.main-page-element-text {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 190px;
    height: 24px;
    margin-bottom: 17px;
    overflow: hidden;
    padding: 0 5px;
}

.main-page-element-full-text {
    position: absolute;
    top: 0;
    text-align: center;
    width: 190px;
    height: 190px;
    margin-bottom: 17px;
    overflow: hidden;
    padding: 10px;
}
