#sub-menu {
    display: flex;
    justify-content: center;
}

.sub-menu-item {
	padding: 10px;
	
	color: black;
	background-color: lightgray;
	
	border-right: solid thin white;
}

.sub-menu-item-active {
	background-color: LightSteelBlue;
}
