.create-contact-button {
    margin-left: 15px;
}

#contactsTable_wrapper {
    margin: 15px 20px;
}

#contactsTable_wrapper input {
    border-radius: 0.75em;
    padding: 0 10px;
}
