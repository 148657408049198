.collection-name {
    margin-bottom: 5px;
}

.peo-recipe {
    border: solid thin;
    background-color: #f0f0f0;
    margin: 0 5px 5px 0;
    overflow: hidden;
    width: 300px;
    border-radius: 15px;
}
.peo-recipe-top-actions {
    background-color: #868686;
    height: 24px;
    margin-left: 0 !important;
}

.peo-recipe-top-actions-close {
    margin-left: 12px;
    text-align: right;
}

.peo-recipe-main-content {
    height: 300px;
    margin-left: 0 !important;
}

.recipe-image {
    border: solid thin;
    margin: 40px 0 0 40px;
    background-color: white;
    width: 220px;
    height: 220px;
}

.peo-recipe-name {
    background-color: #007bff;
    max-width: 100%;
    height: 48px;
    margin-left: 0 !important;
    padding: 0 5px;
}

#process-of-elimination-accordeon .card-header {
    height: 30px;
    padding: 0;
}

#process-of-elimination-accordeon .card-header h2 {
    margin-top: 0 !important;
    height: 25px;
}

#process-of-elimination-accordeon .card-header button {
    padding-top: 0 !important;
    margin-top: -7px;
}

.recipe-poe-tag-to-remove {
    float: left;
    border: solid thin;
    margin: 10px 0 0 10px;
    border-radius: 9px;
    background-color: #007bff;
    width: 200px;
    overflow: hidden;
}

.recipe-poe-tag-to-remove a {
    color: white;
}

.poe-recipe-finding-checkbox {
    margin: 0 auto;
}

.recipe-poe--remove-single-entry {
    cursor: pointer;
}
