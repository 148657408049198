#taskIndexTable_wrapper {
    margin: 15px;
}

#taskIndexTable_wrapper input {
    border-radius: 0.75em;
    padding: 0 10px;
}

.task-tag-list-box {
    float: left;
    border: solid thin;
}

.task-tag-list-box-headline {
    font-weight: bold;
}

.task-tag-list-box {
    padding: 10px;

    display: inline-block;
}

.task-tag-list-element {
    border: solid thin;
    background-color: green;
    border-radius: 13px;
    cursor: pointer;
    text-align: center;
    padding: 0 10px 2px 10px;
    margin-top: 5px;
}

.invisible {
    display: none;
}

#taskTagListing {
    //horizontal-: 0 auto;
}
