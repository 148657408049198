#shopplingListsTable_wrapper {
    margin: 15px;
}

#shopplingListsTable_wrapper input {
    border-radius: 0.75em;
    padding: 0 10px;
}

#productsAndCategoriesTable_wrapper {
    margin: 15px;
}

#productsAndCategoriesTable_wrapper input {
    border-radius: 0.75em;
    padding: 0 10px;
}

#createShoppingListAddEntriesHeader{
    margin-top: 20px;
}

#currentShoppingListEntriesTable_wrapper {
    margin: 15px;
}

#currentShoppingListEntriesTable_wrapper input {
    border-radius: 0.75em;
    padding: 0 10px;
}

.shopping-list-list-element {
    background: #BBBBBB;
    border: solid thin;
    height: 20%;
    font-size: x-large;

    display: flex;
    justify-content: center;
    align-items: center;
}

.shopping-list-details-element {
    background: #BBBBBB;
    border: solid thin;
    height: 20%;
}

.shopping-list-details-element-name {
    width: calc(50% - 2px);
    background: #BBBBBB;
    border: solid thin;
    height: 100%;
    float: left;

    font-size: x-large;

    display: flex;
    justify-content: center;
    align-items: center;
}

.shopping-list-details-element-quantity {
    width: calc(10% - 2px);
    background: #BBBBBB;
    border: solid thin;
    height: 100%;
    float: left;

    font-size: x-large;

    display: flex;
    justify-content: center;
    align-items: center;
}

.shopping-list-details-element-reserved {
    width: calc(20% - 2px);
    border: solid thin;
    height: 100%;
    float: left;

    font-size: x-large;

    display: flex;
    justify-content: center;
    align-items: center;
}

.shopping-list-details-element-done {
    width: calc(20% - 2px);
    border: solid thin;
    height: 100%;
    float: left;

    font-size: x-large;

    display: flex;
    justify-content: center;
    align-items: center;
}

.shopping-list-details-element-done-green {
    color: white;
    background-color: green;
}

.shopping-list-details-element-done-yellow {
    color: black;
    background-color: yellow;
}

.shopping-list-back-button {
    background: #BBBBBB;
    border: solid thin;
    height: 20%;

    font-size: x-large;

    display: flex;
    justify-content: center;
    align-items: center;
}

.shopping-list-execution-item {
	font-size: 30pt;
	
	text-align: center;
	
	border: solid thin black;
	border-radius: 20px;
	
	margin: 20px;
	padding: 20px;
}

.shopping-list-execution-listitem {
    display: flex;
    
    font-size: 30pt;
    
    /* text-align: center; */
    
    border: solid thin black;
    border-radius: 20px;
    
    margin: 20px;
    padding: 20px;
    
    background-color: lightgray;
}

.shopping-list-execution-listitem div {
	border-right: solid thin white;
	padding: 0 10px;
}

.shopping-list-execution-listitem-name {
	width: 60%;
}

.shopping-list-execution-listitem-amount {
    width: 10%;
    text-align: center;
}

.shopping-list-execution-listitem-best-price {
    width: 10%;
    text-align: right;
}

.shopping-list-execution-listitem-reserved {
    width: 10%;
    text-align: center;
}

.shopping-list-execution-listitem-done {
    width: 10%;
    text-align: center;
}

#shopping-list-execution-summary {
	display: flex;
    
    font-size: 30pt;
    
    /* text-align: center; */
    
    border: solid thin black;
    border-radius: 20px;
    
    margin: 20px;
    padding: 20px;
    
    background-color: lightgray;
}

#shopping-list-execution-summary-name {
    width: 70%;
}

#shopping-list-execution-summary-best-price {
    width: 10%;
    text-align: right;
}
