#productsTable_wrapper {
    margin: 15px;
}

#productsTable_wrapper input {
    border-radius: 0.75em;
    padding: 0 10px;
}

#offersHeadline {
    margin-top: 90px !important;
}

#offersTable_wrapper {
    margin: 15px;
}

#offersTable_wrapper input {
    border-radius: 0.75em;
    padding: 0 10px;
}

#back-to-shopping-list-button {
    margin-top: 15px;
}
