.recipe-poe-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recipe-poe-photo-container {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    margin-bottom: 20px;
}
