.consume-search-result {
    border: solid thin !important;
    margin: 5px !important;
    float: left;
    width: 18% !important;
}

.consume-search-result--meal {
    background-color: lawngreen;
    text-align: center !important;
}

.consume-search-result--product {
    background-color: yellow;
    text-align: center !important;
}

.consume-search-result--category {
    background-color: mediumpurple;
    text-align: center !important;
}
