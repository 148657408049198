#login-form {
    margin: 24px auto 15px auto;
    width: 300px;
}

#login-form input {
    width: 100%;
    margin-bottom: 15px;
    border-radius: 0.75em;
    padding: 0 10px;
}

#login-form button {
    float: right;
}
