.text--black {
    color: black;
}

.text--gray {
    color: gray;
}

.text--blue {
    color: blue;
}
