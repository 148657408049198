.recurrent-task-executions-table {
    margin: 0 auto 50px auto;
}

.recurrent-task-executions-table-data {
    padding: 1px 5px;
}

.recurrent-task-executions-table-row-skipped {
    border: solid thin;
    margin-top: -1px;
    color: lightgray;
}

.recurrent-task-executions-table-row-title {
    padding: 5px;
}

.recurrent-task-executions-table-row-status {
    float: left;
    padding: 5px;
}
