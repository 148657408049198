.bulkEditEpisodesColumn {
    float: left;
    margin-top: 10px;
}

.bulkEditEpisodesLastColumn {
    margin-top: 10px;
}

.episode-label {
	margin-bottom: 10px;
}
