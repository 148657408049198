#submit-custom-issue {
    width: 100%;
    margin-top: 20px;
}

#close-custom-issue {
    margin-top: 20px;
}

#cancel-custom-issue {
    margin-top: 20px;
}

#gitlab-issue-description {
    width: 100%;
    height: 450px;
}

#repository-issue-index-notification-area {
    margin-bottom: 15px;
}

#repository-issue-index-button-area {
    margin-bottom: 15px;
}

.assigneeLabel {
    float: left;
    width: 150px;
}

.assigneeChoice > input {
    margin-right: 5px;
}

.assigneeChoice > label {
    margin-right: 5px;
}

.gitlab-issue-filter-card {
    float: left;
    margin-right: 10px;

    padding: 5px;
    background-color: lightgray;
    width: 305px;
}

#gitlab-issue-filter-submit {
    margin-top: 10px;
}

.custom-label-list-item {
    margin-bottom: 5px;
}
