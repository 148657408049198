#flashCardsTable_wrapper {
    margin: 15px 20px;
}

.add-flash-card {
    margin-left: 15px;
}

#flashCardsTable_wrapper input {
    border-radius: 0.75em;
    padding: 0 10px;
}
