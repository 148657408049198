.default-form {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    padding: 50px 0 200px 0;
}

.default-form li {
    background: #ff9696;
    padding-left: 10px;
    margin-left: -40px;
    margin-right: 10px;

    list-style-type: none;
}

.default-form div:not(.radio-type-field) {
    border-radius: 7px;
    border: thin solid gray;

    margin-bottom: 40px;
    padding-left: 10px;
    padding-bottom: 10px;
    background: #f0fffd;
    width: 500px;
}

.default-form div.radio-type-field {
    padding-top: 10px;
}

.default-form div.radio-type-field input {
    width: 14px;
}

.default-form div.radio-type-field label {
    margin-left: 20px;
    margin-top: -24px;
}

.default-form label {
    display: block;
    margin-top: -13px;
    width: 200px;
    padding: 0 5px;
    background: #dcfffd;

    border-radius: 7px;
    border: thin solid gray;
}

.default-form label.required::after {
    content: '*';
    color: black; /* Farbe des Sternchens anpassen */
    margin-left: 5px; /* Abstand zwischen Label-Text und Sternchen */
}

.default-form input {
    width: 480px;
}

.default-form textarea {
    width: 480px;
    height: 300px;
}

.default-form select {
    width: 480px;
}
