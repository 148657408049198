.calendar {
    width: 1785px;
    height: 1530px;
    margin: 0 auto;
}

.calendar-weekday {
    float: left;
    width: 250px;

    margin: 0 5px 15px 0;
    padding: 5px;

    background-color: #d6d6d6;

    border-radius: 15px;

    text-align: center;
}

.calendar-day--placeholder {

}

.calendar-day {
    float: left;

    background-color: #d6d6d6;

    width: 250px;
    height: 250px;

    margin: 0 5px 5px 0;
    padding: 5px;

    border-radius: 15px;

    overflow: hidden;
}

.current-day {
    background-color: #e9e9e9;
}

.calendar-day-top-line {
    border-bottom: solid thin white;
}

.calendar-day-top-line-left {
    width: 50px;
    float: left;
}

.calendar-day-top-line-right {
    text-align: end;
}

.calendar-day-entry {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
